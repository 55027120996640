/*----------------------------------------------
    ## All section style
----------------------------------------------*/
/*****featured-area*****/
.featured-area {
  .btn-base {
    margin-right: 15px;
  }
}
.featured-product {
  padding: 0;
  margin: 0 0 25px 0;
  text-align: center;
  .thumb {
    display: inline-block;
    list-style: none;
    width: 80px;
    height: 80px;
    background: $white-color;
    text-align: center;
    line-height: 57px;
    margin-right: 26px;
    margin-bottom: 30px;
    transition: 0.4s;
    border-radius: 3px;
    &:hover {
      box-shadow: 0px 6px 20px 3px rgba(0, 0, 0, 0.1);
    }
    a {
      padding: 12px;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
/*****all-item-area*****/
.all-item-area {
  .btn {
    margin-top: 30px;
  }
  form {
    float: right;
  }
  .filter-area {
    border-radius: 5px;
    background-color: $white-color;
    box-shadow: 0px 6px 47px 3px rgba(37, 37, 37, 0.05);
    padding: 64px 70px;
    margin-bottom: 67px;
    .isotope-filters {
      display: inline-block;
    }
    .nice-select {
      border: 2px solid rgb(210, 237, 233);
      border-radius: 5px;
      height: 60px;
      line-height: 60px;
      float: right;
      margin-top: -10px;
      font-size: 18px;
      font-weight: 500;
      color: var(--paragraph-color);
      padding: 0 93px 0 48px;
      &:after {
        border-bottom: 2px solid rgb(9, 64, 116);
        border-right: 2px solid rgb(9, 64, 116);
        width: 10px;
        height: 10px;
        right: 48px;
      }
    }
  }
}
.all-item-area-2 {
  .all-isotope-item {
    .item-details {
      justify-content: space-between;
      position: relative;
      padding-bottom: 20px;
      &:before {
        content: "";
        position: absolute;
        height: 3px;
        width: 100%;
        left: 0;
        background: rgb(229, 229, 229);
        bottom: 68px;
      }
      .price {
        margin: -33px -11px 0 0;
      }
    }
  }
}
.item-isotope-btn {
  .button {
    border-radius: 5px;
    height: 40px;
    box-shadow: 0px 6px 47px 3px rgba(37, 37, 37, 0.05);
    border: 0;
    background: $main-color;
    font-size: 16px;
    color: $white-color;
    font-weight: 700;
    cursor: pointer;
    padding: 0 22px 0 20px;
    margin-left: 17px;
    margin-bottom: 20px;
    font-family: $header-font;
    transition: $transition;
  }
  p {
    color: $main-color;
    font-size: 1.3rem;
  }
}
.all-isotope-item {
  margin-bottom: 30px;

  &:hover {
    .thumb {
      &:after {
        background: rgba(255, 255, 255, 0.678);
      }
      .btn {
        opacity: 1;
      }
    }
  }
  .thumb {
    position: relative;
    background: $white-color;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(28, 0, 59, 0);
      left: 0;
      top: 0;
      border-radius: 10px 10px 0 0;
      transition: $transition;
    }
    a {
      img {
        border-radius: 10px 10px 0 0;
      }
    }
    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      opacity: 0;
      height: 50px;
      line-height: 50px;
      padding: 0 40px;
      margin-left: -85px;
      margin-top: -25px;
      box-shadow: none;
    }
  }
  .item-details {
    box-shadow: 0px 6px 47px 3px rgba(37, 37, 37, 0.05);
    border-radius: 0 0 10px 10px;
    padding: 25px 28px 22px;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .price {
      border-radius: 5px;
      background-color: rgba(73, 0, 155, 0.102);
      height: 32px;
      display: inline-block;
      line-height: 34px;
      padding: 0 12px;
      font-size: 16px;
      margin-bottom: 18px;
      font-family: $header-font;
      color: rgb(73, 0, 155);
      font-weight: 700;
    }
    .ratting {
      font-size: 14px;
      i {
        color: $accent-color;
        margin-right: 6px;
        &.star-o {
          color: #d6d6d6;
        }
      }
      span {
        color: rgb(86, 88, 114);
      }
    }
    h4 {
      font-size: 22px;
      color: $main-color;
      &:hover {
        color: $grey-color;
      }
    }
    p {
      color: $grey-color;
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 0;
    }
    .author-details {
      display: flex;
      align-items: center;
      margin-top: 40px;
      img {
        border-radius: 50%;
        height: 40px;
      }
      span {
        margin: 0 0 0 18px;
        color: $main-color;
      }
    }
  }
}
.results-null {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/*****fun-fact-area*****/
.fun-fact-area {
  padding-bottom: 58px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.single-fact-wrap {
  background-attachment: fixed;
  background-position: center !important;

  margin-bottom: 30px;
  .thumb {
    height: 48px;
  }
  h2 {
    margin: 20px 0 2px;
    span {
      font-size: 3rem;
      font-weight: 700;
      color: $white-color;
    }
  }
  .fact-title {
    font-weight: 500;
    font-size: 1.2rem;
    color: $white-color;
  }
}

/*****testimonial-area*****/
.slide-container {
  margin-bottom: 50px;
}
.each-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}
.slider-image {
  width: 90%;
}
.slider-video {
  margin-bottom: 50px;
}
.testimonial-slider .owl-nav {
  display: none;
}
.testimonial-slider .item {
  border-radius: 10px;
}
.testimonial-slider .item img {
  width: auto;
  height: auto;
}
.testimonial-slider .item .testimonial-wrap {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 6px 16px 3px rgba(37, 37, 37, 0.05);
  padding: 41px 38px 35px;
  margin: 15px 15px 30px;
}
.testimonial-slider .item .testimonial-wrap .icon {
  display: inline-block;
  padding: 21px 25px;
}
.testimonial-slider .item .testimonial-wrap p {
  margin: 8px 0 33px;
}
.testimonial-slider .item .testimonial-wrap .thumb {
  display: inline-block;
}
.testimonial-slider .item .testimonial-wrap .thumb img {
  border-radius: 50%;
}
.testimonial-slider .item .testimonial-wrap .name {
  font-size: 20px;
  margin: 12px 0 3px;
}
.testimonial-slider .item .testimonial-wrap .designation {
  margin: 0;
}

/*****loading*****/
.loadingdots {
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*----------------------------------------------
    ## Product Page style
----------------------------------------------*/
.product-category-container {
  width: 90%;
}

.single-product-wrap {
  padding: 32px 30px 36px;
  box-shadow: 0px 6px 47px 3px rgba(37, 37, 37, 0.05);
  border-radius: 10px;
  .thumb {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(28, 0, 59, 0);
      left: 0;
      top: 0;
      border-radius: 10px;
      transition: all 0.4s ease-in;
    }
    img {
      border-radius: 10px;
    }
    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      opacity: 0;
      height: 50px;
      line-height: 50px;
      padding: 0 40px;
      margin-left: -85px;
      margin-top: -58px;
      box-shadow: none;
      &.btn-buy {
        margin-top: 22px;
        padding: 0 54px;
      }
    }
  }
  &:hover {
    .thumb:after {
      background: rgba(28, 0, 59, 0.6);
    }
    .thumb .btn {
      opacity: 1;
    }
  }
  .single-product-details {
    h4 {
      margin-top: 37px;
      font-size: 1.2rem;
      margin-bottom: 0;
    }
    p {
      margin: 6px 0 0 0;
    }
  }
}
/*****product-tab*****/
.product-tab {
  margin: 32px 0 90px;
  border-radius: 10px;
  background-color: $white-color;
  box-shadow: 0px 6px 47px 3px rgba(37, 37, 37, 0.05);
  padding: 35px 50px 43px;
  .nav-pills {
    display: block;
    margin-bottom: 36px;
    .nav-item {
      margin-right: 15px;
      display: inline-block;
      .nav-link {
        border-radius: 5px;
        background-color: $secondary-grey-color;
        color: $grey-color;
        height: 40px;
        line-height: 41px;
        font-size: 18px;
        font-weight: 500;
        font-family: $body-font;
        padding: 0 20px;
        text-align: center;
        &.active {
          color: $white-color;
          background-color: $main-color;
        }
      }
    }
  }
  .tab-content {
    p {
      margin-bottom: 32px;
    }
    .title {
      font-size: 2rem;
      margin-top: 3rem;
      color: $main-color;
      margin-bottom: 1.5rem;
    }
    ul {
      padding: 0 0 0 27px;
      margin: 0;
      li {
        list-style: none;
        margin-bottom: 22px;
        p {
          margin-bottom: 0;
          &.font-medium {
            font-weight: 500;
            position: relative;
            margin-bottom: 2px;
            &:before {
              content: "";
              position: absolute;
              height: 7px;
              width: 7px;
              border-radius: 50%;
              background: $main-color;
              top: 50%;
              left: -26px;
              margin-top: -3px;
            }
          }
        }
      }
    }
  }
}
/*****product-slider*****/
.product-slider {
  .item {
    padding-bottom: 30px;
  }
  .owl-nav {
    position: absolute;
    top: -80px;
    right: 0;
    button {
      background: transparent;
      border: 0;
      width: auto;
      height: auto;
      line-height: normal;
      padding-right: 0;
      padding-left: 24px;
      color: $main-color;
      &:hover {
        color: $main-color;
      }
    }
  }
}
