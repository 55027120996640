.partner-section-body-image-container {
    width: 8rem;
    height: 8rem;
}

.partner-section-body-image {
    width: auto;
    height: auto;
}

.emkpProductDetails-moduleReview {
    ul li {
        list-style: disc !important;
    }

    p {
        list-style: disc !important;
    }
}

.emkpProductDetails-support-container {
    p a:hover {
        color: #9c9eb1;
    }
}