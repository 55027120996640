// colours
$main-color: #202452;
$accent-color: #fad614;
$secondary-color: #f3f3f3;
$grey-color: #313131;
$secondary-grey-color: #d8d8d8;
$code-grey-color: #e9e9e9;
$white-color: #ffffff;
$black-color: #000000;

// typography
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600;700&family=Rubik:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&family=Source+Sans+Pro:wght@400;600;700&display=swap");

$header-font: "Poppins", sans-serif;
$body-font: "Rubik", sans-serif;
$body-font-size: 13px;
$line-height: 1.7;

//  transition
$transition: all 0.4s ease-in;
$transition-long: all 0.5s ease-in;
$box-shadow: 0px 2px 12px #97a1b21a;
$box-shadow2: 0px 20px 40px rgba(0, 0, 0, 0.12);
