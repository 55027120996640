/*---------------------------------------
    ## Button
---------------------------------------*/
.btn {
  height: 60px;
  line-height: 60px;
  padding: 0 38px;
  overflow: hidden;
  position: relative;
  border: 0;
  @include transition(all, 0.5s, ease);
  font-weight: 500;
  font-size: 1.1rem;
  display: inline-block;
  transform: perspective(1px) translateZ(0);
  border-radius: 50px;
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
  img {
    margin-right: 7px;
    margin-top: -1px;
  }
  &.btn-border {
    border: 2px solid $main-color;
    font-size: 15px;
    color: $main-color;
    &:hover {
      background: $main-color;
      color: $white-color;
    }
  }
}
.btn-base {
  color: $white-color;
  border: 0;
  background: $main-color;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $main-color;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-duration: 0.4s;
  }
  &:hover,
  &:focus {
    color: $white-color;
    &:before {
      transform: scaleX(1);
    }
  }
}
.btn-white {
  color: $white-color;
  border: 0;
  background: $main-color;
  box-shadow: 0px 6px 47px 3px rgba(37, 37, 37, 0.05);
  padding: 0 55px;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $main-color;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-duration: 0.4s;
  }
  &:hover,
  &:focus {
    color: $white-color;
    &:before {
      transform: scaleX(1);
    }
  }
}
.btn-search {
  background-color: $accent-color;
  margin-left: 12px;
  border-radius: 20px;
}
.ratting {
  font-size: 14px;
  i {
    color: $accent-color;
    margin-right: 6px;
    &.star-o {
      color: $white-color;
    }
  }
}
.pagination {
  display: inline-flex;
  width: 50%;
  align-items: center;
  align-self: center;
  .page-item {
    width: 10%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    cursor: pointer;
    height: 40px;
    border-radius: 2.5px;
    // border: solid rgb(56, 174, 221) 1.5px;
    margin-left: 10px;
      &:hover {
        background: $white-color;
        color: $main-color;
      }
      &:focus {
        background: $main-color !important;
        color: $white-color;
      }
      // &:active{
      //   background: rgb(56, 174, 221);
      //   color:$white-color;
      // }
      a{
        width: 80%;
        font-size: 18px;
        align-self: center;
        height: 40px;
      }
  }
}

/*------------------------------------------------
    ## Single-input-wrap
------------------------------------------------*/
.single-input-wrap {
  border-radius: 0px;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  .input-group-prepend {
    z-index: 9;
    .input-group-text {
      width: auto;
      height: auto;
      border: 0;
      text-align: center;
      color: $main-color;
      position: absolute;
      right: 25px;
      top: 20px;
      padding: 0;
      background: transparent;
      display: inline-block;
      margin-left: 8px;
    }
  }
  input {
    height: 60px;
    width: 100%;
    line-height: 60px;
    font-size: 14px;
    padding: 0 20px;
    border: 1px solid $white-color;
    background: transparent;
    border-radius: 0px;
    color: $grey-color;
    transition: $transition;
    &::placeholder {
      font-size: 14px;
      color: $grey-color;
      line-height: 60px;
    }
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      outline: 0;
      border: 1px solid $white-color;
    }
  }
  textarea {
    width: 100%;
    padding: 22px 20px;
    font-size: 14px;
    background: transparent;
    border: 1px solid $main-color;
    color: $grey-color;
    border-radius: 0px;
    transition: $transition;
    &::placeholder {
      color: $grey-color;
      font-size: 14px;
    }
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      outline: 0;
      border: 1px solid $white-color;
    }
  }
}
/*------------------------------------------------
    ## Section title
------------------------------------------------*/
.section-title {
  margin-bottom: 47px;
  position: relative;
  h2 {
    font-size: 3rem;
    margin-bottom: 0;
  }
  p {
    font-size: 1.1rem;
    margin: 5px 0 0 0;
  }
  &.style-white {
    h2 {
      color: $white-color;
    }
    p {
      color: $white-color;
    }
  }
  .btn {
    margin-top: 30px;
  }
}

/*------------------------------------------------
    ## Back Top
------------------------------------------------*/
.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 44px;
  height: 44px;
  color: $white-color;
  background-color: $main-color;
  text-align: center;
  line-height: 44px;
  z-index: 99;
  font-size: 25px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: none;
  animation: backto-top-bounce 4s infinite ease-in-out;
  border-radius: 3px;
}
@-webkit-keyframes backto-top-bounce {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
}

@keyframes backto-top-bounce {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
}

.spinner {
  margin: 120px auto;
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

/*-----------------------------------------
    ## Breadcumb 
------------------------------------------*/
.breadcrumb-area {
  padding: 150px 0 30px;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  .section-title {
    margin-bottom: 0;
  }
  .page-title {
    font-size: 2rem;
    font-weight: 500;
    font-family: $body-font;
    margin-bottom: 3px;
    color: $white-color;
  }
  .page-list {
    margin: 0;
    padding: 0;
    color: $white-color;
    li {
      font-size: 1.1rem;
      font-weight: 400;
      list-style: none;
      display: inline-block;
      position: relative;
      padding-left: 8px;
      &:after {
        position: absolute;
        left: 0;
        top: 1px;
        content: "\f105";
        font-family: "fontawesome";
      }
      &:first-child {
        padding-left: 0;
        &:after {
          display: none;
        }
      }
    }
  }
  .v-bc-inner {
    .media-left {
      margin-right: 20px;
    }
    .media-body {
      h3 {
        color: $white-color;
        font-weight: 500;
      }
      p {
        color: $white-color;
        margin-bottom: 0;
        height: 36px;
        line-height: 36px;
        padding: 0 20px;
        border-radius: 5px;
        background: $main-color;
        display: inline-block;
      }
    }
  }
  .v-bc-list-inner {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-left: 57px;
      .media {
        .media-left {
          margin-right: 15px;
        }
        .media-body {
          text-align: left;
          h4 {
            color: $white-color;
            font-weight: 500;
            margin-bottom: 0;
          }
          p {
            color: $white-color;
            margin-bottom: 0;
          }
        }
      }
      .btn {
        border-radius: 7px;
        height: 50px;
        line-height: 50px;
        padding: 0 32px;
      }
    }
  }
}

/*--------------------------------------------------------------
# Globals
--------------------------------------------------------------*/
.bg-sky-blue {
  background: $white-color;
}

.pd-top-15 {
  padding-top: 15px;
}

.pd-top-10 {
  padding-top: 10px;
}
.pd-top-90 {
  padding-top: 90px;
}
.pd-top-100 {
  padding-top: 100px;
}
.pd-bottom-30 {
  padding-bottom: 30px;
}
.pd-bottom-100 {
  padding-bottom: 100px;
}

input[type="checkbox"] {
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
}

/* Might want to wrap a span around your checkbox text */
.form-check-label {
  margin-left: 10px;
}
