.emkpFooter-info-contactLocation-mediaBody,
.emkpFooter-info-contactEmail-mediaBody,
.emkpFooter-info-contactTel-mediaBody {
    a:hover {
        color: #9c9eb1;
    }
}
.emkpFooter-info-siteMap-listItems-element {
    a p:hover {
        color: #9c9eb1;
    }
}