.image-gallery-slides {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-gallery-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 35rem;
    max-height: 35rem;
}

.image-gallery {
    .image-gallery-content.fullscreen {
        .image-gallery-slides {
            height: 100vh;
        }
        .image-gallery-slide {
            top: auto;
        }

        .video-wrapper {
            width: 95vw;
            height: 95vh;
        }
    }
}

.video-wrapper {
    width: 50em;
    height: 30em;
}

.video-iframe {
    width: 100%;
    height: 100%;
}