.banner-area-inner {
  padding-bottom: 155px;
  form {
    position: relative;
    margin-top: 55px;
    .nice-select {
      height: 80px;
      position: absolute;
      line-height: 80px;
      border-radius: 10px 0 0 10px;
      font-size: 1.2rem;
      color: $grey-color;
      font-family: $body-font;
      font-weight: 500;
      border: 0;
      border-right: 2px solid rgb(225, 225, 225);
      padding: 0 97px 0 50px;
      &:after {
        right: 31px;
        height: 9px;
        width: 9px;
        border-bottom: 2px solid rgb(9, 64, 116);
        border-right: 2px solid rgb(9, 64, 116);
      }
    }
    .form-control {
      height: 80px;
      line-height: 80px;
      border: 0;
      border-radius: 0;
      border-radius: 10px;
      padding: 0 110px 0 28px;
    }
    .btn {
      height: 80px;
      line-height: 80px;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0 10px 10px 0;
      background: $accent-color;
      padding: 0 33px 0 40px;
    }
  }
}
.banner-inner {
  position: relative;
  z-index: 3;
  padding-top: 125px;
  h2 {
    font-size: 4rem;
    padding-top: 3rem;
    position: relative;
    margin-bottom: 0px;
    font-weight: 400;
    color: $white-color;
    font-family: $header-font;
    animation: 1.6s 0.6s fadeInUp both;
    span {
      font-weight: 700;
    }
  }
  p {
    position: relative;
    font-size: 1.4rem;
    color: $white-color;
    margin-top: 32px;
    animation: 1.6s 0.8s fadeInUp both;
  }
  .btn-wrap {
    animation: 1.6s 1s fadeInUp both;
    margin-top: 35px;
    .btn {
      background: $main-color;
      height: 50px;
      line-height: 50px;
      padding: 0 55px;
      font-size: 1rem;
    }
  }
}
