/*--------------------------------------------------
    ##Footer
---------------------------------------------------*/
footer {
  .widget {
    margin-bottom: 1rem;
  }
  .icon-width {
    width: 20px;
  }
}
.footer-area {
  background-color: $white-color;
}

.social-area {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    display: inline-block;
    margin-top: 1rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
