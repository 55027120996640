/*---------------------------------------------------
	widget styles
----------------------------------------------------*/
.footer-widget {
  .widget-title {
    font-size: 1.5rem;
    color: $main-color;
    margin-bottom: 10px;
    border: 0;
    padding-bottom: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      a {
        color: $main-color;
        line-height: 2.25;
        &:hover {
          color: $main-color;
        }
      }
    }
  }
}
.widget {
  margin-bottom: 32px;
}
.widget-border {
  border: 2px solid $white-color;
}
.widget-title {
  border-bottom: 2px solid $white-color;

  margin-top: 10px;
}

/**widget_contact**/
.widget_contact {
  margin-left: 100px;
  .media {
    margin-bottom: 19px;
    .thumb {
      margin: 6px 23px 0 0;
    }
    .media-body {
      p {
        color: $main-color;
        margin-bottom: 5px;
      }
    }
  }
  .social-area {
    margin-top: 40px;
    li {
      a {
        line-height: 47px;
      }
    }
  }
}

/**copyright-area**/
.copyright-area {
  border-top: 2px solid $secondary-grey-color;
  padding: 47px 0;
  p {
    color: $main-color;
    margin-bottom: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin-left: 33px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 19px;
        width: 1px;
        background: $main-color;
        top: 6px;
        right: -17px;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      a {
        color: $main-color;
        transition: $transition;
        &:hover {
          color: $main-color;
        }
      }
    }
  }
}

/**widget-client**/
.widget-client {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 6px 47px 3px rgba(37, 37, 37, 0.05);
  .thumb {
    border-radius: 50%;
    background-color: rgb(249, 243, 255);
    display: inline-block;
    width: 96px;
    height: 96px;
    line-height: 90px;
    margin-bottom: 24px;
  }
  .partnertitle {
    color: $main-color;
  }
}
/**widget-list**/
.widget-list {
  border-radius: 10px;
  background-color: $white-color;
  box-shadow: 0px 6px 47px 3px rgba(37, 37, 37, 0.05);
  list-style: circle;
  .title {
    color: $main-color;
    margin-left: 0;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      display: flex;
      line-height: 29.5px;
      span {
        width: 136px;
        display: inline-table;
        font-size: 16px;
        font-weight: 700;
        margin-right: 7px;
      }
      p {
        display: block;
      }
    }
  }
}

/**widget-tags**/
.widget-tags {
  .tagcloud {
    margin-top: 30px;
    a {
      height: 30px;
      color: rgb(105, 105, 105);
      font-size: 15px;
      font-weight: 600;
      display: inline-block;
      background: rgb(245, 245, 245);
      line-height: 30px;
      font-family: var(--heading-font);
      padding: 0 12px;
      margin: 0 3px 8px 0;
      transition: all 0.3s ease-in;
      position: relative;
      &:hover {
        background: $main-color;
        color: #ffffff;
      }
    }
  }
}

/**widget-category**/
.widget-category {
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      font-size: 18px;
      font-weight: 600;
      color: $grey-color;
      font-family: $header-font;
      border-bottom: 2px solid #eaeaea;
      padding: 16px 0 14px;
      a {
        transition: 0.4s;
        &:hover {
          color: $main-color;
        }
      }
      &:last-child {
        border-bottom: 0;
      }
      i {
        float: right;
        margin-top: 6px;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.widget-product-sorting label,
.widget-product-sorting input {
  font-size: 1rem;
  letter-spacing: 0.15px;
  color: $main-color;
  margin-right: 4px;
  margin-bottom: 0;
  line-height: 2rem;
  display: inline-block;
  max-width: 40px;
  border: 1px solid $secondary-grey-color;
}
.widget-product-sorting label:focus,
.widget-product-sorting input:focus {
  border: 1px solid $main-color;}
.widget-product-sorting button {
  line-height: 10px;
  padding: 0 10px;
  background: $main-color;
  font-size: 1rem;
  letter-spacing: 0.42px;
  color: $white-color;
  border: 1px solid $main-color;
  border-radius: 3px;
  cursor: pointer;
}
.widget-product-sorting button:focus {
  outline: 0;
}

.sidebar-area {
  .widget {
    padding: 1rem 2rem;
  }
  .widget-search,
  .widget-consultation {
    padding: 0;
  }
  .message {
    padding-top: 10px;
  }
  .close-message {
    padding-left: 2px;
    padding-top: 7px
  }
}
